import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import InitialDataInterface from '../interfaces/initalData.interface';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentYear = (new Date()).getFullYear();


  dados: InitialDataInterface;
  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.dados = this.route.snapshot.data.initialData as InitialDataInterface;
  }

}
