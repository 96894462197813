<!-- footer Start -->
<section class="footer">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="mb-4">
                    <a href="layout-one"><img src="assets/images/logo-teste3.png" alt="" class="logo-dark" height="40" /></a>
                    <p class="text-muted mt-4 mb-2">{{dados.email}}</p>
                    <h6 class="text-muted font-weight-normal">{{ dados.telefone.formatado }}</h6>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="row d-flex justify-content-end">
                    <!-- <div class="col-md-4">
                        <h6 class="footer-list-title text-dark mb-3">Services</h6>
                        <ul class="list-unstyled company-sub-menu">
                            <li><a href="">Digital Marketing</a></li>
                            <li><a href="">Business Analysis</a></li>
                            <li><a href="">How It Work</a></li>
                            <li><a href="">Fix & Flip</a></li>
                            <li><a href="">Social Activation</a></li>
                        </ul>
                    </div>
                    <div class="col-md-4">
                        <h6 class="footer-list-title text-dark mb-3">About Us</h6>
                        <ul class="list-unstyled company-sub-menu">
                            <li><a href="">Contact Us</a></li>
                            <li><a href="">FAQs</a></li>
                            <li><a href="">Blog</a></li>
                            <li><a href="">Privacy Policy</a></li>
                        </ul>
                    </div> -->
                    <div class="col-md-4">
                        <h6 class="footer-list-title text-dark mb-3">Nosso Endereço</h6>
                        <p class="text-muted f-14">Rua 4, N 1400, Setor Central,Goiânia Goiás</p>
                        <p class="text-muted pb-2">DAXS TECNOLOGIA E GESTAO EMPRESARIAL LTDA</p>
                        <!-- <h6 class="text-muted pb-2">Email: contato@sistemacotar.com.br</h6> -->
                        <!-- <ul class="list-unstyled footer-social-list mt-4">
                            <li class="list-inline-item"><a href="#"><i class="mdi mdi-facebook"></i></a></li>
                            <li class="list-inline-item"><a href="#"><i class="mdi mdi-instagram"></i></a></li>
                            <li class="list-inline-item"><a href="#"><i class="mdi mdi-linkedin"></i></a></li>
                        </ul> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-md-12">
                <div class="text-center text-muted">
                    <p class="mb-0 f-15"> {{currentYear}} © Sistema Cotar.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- footer End -->
