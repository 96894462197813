<!-- Service Start -->
<section class="section" id="services">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8">
                <div class="title text-center mb-5">
                    <h3 class="font-weight-normal text-dark">Nossas <span class="text-warning">Soluções</span></h3>
                    <p class="text-muted">Maecenas tempus tellus eget condimentum rhoncus sem quam semper libero pulvinar hendrerit id lorem.</p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-headphones service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">Awesome Support</h5>
                    <p class="text-muted service-subtitle mb-4">Et harum quidem rerum facilis expedita distinctio nam libero tempore cum quibusdam nobis.</p>
                    <a href="#" class="read-more-icon"><span class="right-icon">&#8594;</span></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-tools service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">Solutions Business</h5>
                    <p class="text-muted service-subtitle mb-4">Et harum quidem rerum facilis expedita distinctio nam libero tempore cum quibusdam nobis.</p>
                    <a href="#" class="read-more-icon"><span class="right-icon">&#8594;</span></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-display1 service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">Digital Design</h5>
                    <p class="text-muted service-subtitle mb-4">Et harum quidem rerum facilis expedita distinctio nam libero tempore cum quibusdam nobis.</p>
                    <a href="#" class="read-more-icon"><span class="right-icon">&#8594;</span></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-cup service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">Goal Business</h5>
                    <p class="text-muted service-subtitle mb-4">Et harum quidem rerum facilis expedita distinctio nam libero tempore cum quibusdam nobis.</p>
                    <a href="#" class="read-more-icon"><span class="right-icon">&#8594;</span></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-light service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">Branding Identity</h5>
                    <p class="text-muted service-subtitle mb-4">Et harum quidem rerum facilis expedita distinctio nam libero tempore cum quibusdam nobis.</p>
                    <a href="#" class="read-more-icon"><span class="right-icon">&#8594;</span></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-box service-warning">
                    <div class="mb-5">
                        <i class="pe-7s-graph1 service-icon"></i>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">Dynamic Growth</h5>
                    <p class="text-muted service-subtitle mb-4">Et harum quidem rerum facilis expedita distinctio nam libero tempore cum quibusdam nobis.</p>
                    <a href="#" class="read-more-icon"><span class="right-icon">&#8594;</span></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Service End -->
