<!-- Features Start -->
<section class="section" id="features">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8">
                <div class="title text-center mb-5">
                    <h3 class="font-weight-normal text-dark">Nossos <span class="text-warning">Recursos</span></h3>
                    <p class="text-muted">Maecenas tempus tellus eget condimentum rhoncus sem quam semper libero pulvinar hendrerit id lorem.</p>
                </div>
            </div>
        </div>

        <div class="row align-items-center">
            <div class="col-md-5" style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img src="assets/images/features/img-1.png" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
            <div class="col-md-6 offset-md-1">
                <div class="features-box mt-5 mt-sm-0 mb-4">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-laptop-mac"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">Activity Reporting</h5>
                    <p class="text-muted mb-3 f-15">Et harum quidem rerum facilise expedita distinctio nam libero tempore cum soluta nobis as eligendi optio cumque nihil quis nostrum exercitationem impedit minus omnis repellendus.</p>
                    <a href="#" class="f-16 text-warning">Read More <span class="right-icon ml-2">&#8594;</span></a>
                </div>
            </div>
        </div>

        <div class="row align-items-center mt-5">
            <div class="col-md-6">
                <div class="features-box mb-4">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-account-group"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">Team Management</h5>
                    <p class="text-muted mb-3 f-15">Sed perspiciatis unde omnis natus error voluptatem accusantium doloremque laudantium totam rem aperiam eaque ipsa quae ab illo excepturi sint occaecati cupiditate architecto.</p>
                    <a href="#" class="f-16 text-warning">Read More <span class="right-icon ml-2">&#8594;</span></a>
                </div>
            </div>
            <div class="col-md-5 offset-md-1 mt-5 mt-sm-0" style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img src="assets/images/features/img-2.png" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
        </div>

        <div class="row align-items-center mt-5">
            <div class="col-md-5" style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img src="assets/images/features/img-3.png" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
            <div class="col-md-6 offset-md-1">
                <div class="features-box mt-5 mt-sm-0 mb-4">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-chart-bell-curve"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">Marketing Analysis</h5>
                    <p class="text-muted mb-3 f-15">At vero eos accusamus iusto odio soluta nobis est eligendi optio dignissimos ducimus qui blanditiis praesentium as voluptatum deleniti corrupti quos dolores molestias occaecati.</p>
                    <a href="#" class="f-16 text-warning">Read More <span class="right-icon ml-2">&#8594;</span></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Features End -->
