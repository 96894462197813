import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import InitialDataInterface from '../interfaces/initalData.interface';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit,OnDestroy {
addTokenLog(arg0: string,$event: any) {
throw new Error('Method not implemented.');
}
  type: string;
  message: string = null;
  messageContact: string = null;
  contactFormGroup: FormGroup;
  newsletterFormGroup: FormGroup;
  environment = environment;
  token: any;
  dados: InitialDataInterface;
  $destroyed: Subject<null>;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _http: HttpClient,
    private _formBuilder: FormBuilder,
  ) {
    this.$destroyed = new Subject();
    this.newsletterFormGroup = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    })

    this.contactFormGroup = this._formBuilder.group({
      nome: ['', Validators.required],
      genero: ['', Validators.required],
      telefone: ['', Validators.required],
      email: ['', Validators.required],
      assunto: ['', Validators.required],
      mensagem: ['', Validators.required],
      recaptchaReactive: [null, Validators.required]
    })
  }
  ngOnDestroy(): void {
    this.$destroyed.next(null)
    this.$destroyed.complete()
  }  

  ngOnInit() {
    this.dados = this._activatedRoute.snapshot.data.initialData as InitialDataInterface;
  }
  contact() {
    this.messageContact = null;
    console.log(this.contactFormGroup.getRawValue());
    this.contactFormGroup.markAllAsTouched();
    if (this.contactFormGroup.invalid) {
      this.type = 'danger';
      this.messageContact = "Ops, existem campos invalidos.";
      return;
    }
    this._http.post(environment.endpoint.contact, this.contactFormGroup.getRawValue())
    .pipe(takeUntil(this.$destroyed))
    .subscribe((res) => {
      this.type = 'success';
      this.messageContact = "Ótimo, sua mensagem foi enviada com sucesso.";
      this.contactFormGroup.reset();
    }, (err) => {
      this.type = 'danger';
      this.messageContact = "Ops, nāo foi possivel enviar sua mensagem.";
      console.error(err)
    })
  }

  subscribeTo() {
    this.message = null;
    console.log(this.newsletterFormGroup.getRawValue());
    this.newsletterFormGroup.markAllAsTouched()
    if (this.newsletterFormGroup.invalid) {
      this.type = 'danger';
      this.message = "Ops, existem campos invalidos.";
      return;
    }
    this._http.get(environment.endpoint.newsletter, {
      params: {
        email: this.newsletterFormGroup.getRawValue()
      }
    }).pipe(takeUntil(this.$destroyed))
    .subscribe((res) => {
      this.type = 'success';
      this.message = "Ótimo, seu e-mail foi cadastrado para receber nossas atualizações.";
      this.newsletterFormGroup.reset();
      return;
    }, (err) => {
      this.type = 'danger';
      this.message = "Ops, nāo foi possivel cadastrar seu e-mail.";
      console.error(err)
    },)
  }
}
