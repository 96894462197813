<!-- Contact Us Start -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6 col-md-8">
        <div class="title text-center mb-5">
          <h3 class="font-weight-normal text-dark">Entre em <span class="text-warning"> Contato</span> Conosco</h3>
          <!-- <p class="text-muted">Maecenas tempus tellus eget condimentum rhoncus sem quam semper libero pulvinar hendrerit id lorem.</p> -->
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-4">
        <div class="contact-address">
          <h4 class="text-dark mb-4">Informações de Contato</h4>
          <p class="text-muted f-15">Entre em contato no Telefone <a href="tel:{{dados.telefone.naoFormatado}}">{{dados.telefone.formatado}}</a>
          </p>
          <p class="text-muted f-15 mb-4">ou mande um email para <a
              href="mailto:{{dados.email}}">{{dados.email}}</a></p>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="contact-address">
              <!-- <h5 class="text-dark mb-3 f-16">Address - A</h5> -->
              <!-- <p class="text-muted f-15">3165 Roosevelt Wilson Riverside, CA 92507</p> -->
            </div>
          </div>
          <div class="col-md-6">
            <div class="contact-address">
              <!-- <h5 class="text-dark mb-3 f-16">Address - B</h5> -->
              <!-- <p class="text-muted f-15">1121 Bombardier Way Southfield, MI 48075</p> -->
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-7 offset-lg-1">
        <div class="custom-form mt-4 mt-lg-0">
          <div id="message"></div>
          <form [formGroup]="contactFormGroup" id="contact-form">
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group app-label">
                  <label for="nome">Nome</label>
                  <input formControlName="nome" id="nome" name="nome" type="text" class="form-control" placeholder="Insira seu Nome.." required maxLength="50"
                    [class.is-invalid]="contactFormGroup.get('nome').touched && contactFormGroup.get('nome').hasError('required')">
                  <div class="invalid-feedback" *ngIf="contactFormGroup.get('nome').touched && contactFormGroup.get('nome').hasError('required')">Campo obrigatório</div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group app-label">
                  <label for="email">Endereço de E-mail</label>
                  <input formControlName="email" id="email" name="email" type="email" class="form-control" placeholder="Insira seu e-mail.." required maxLength="50"
                    [class.is-invalid]=" contactFormGroup.get('email').touched && contactFormGroup.get('email').hasError('required')">
                  <div class="invalid-feedback" *ngIf="contactFormGroup.get('email').touched && contactFormGroup.get('email').hasError('required')">Campo obrigatório</div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group app-label">
                  <label for="telefone">Telefone</label>
                  <input formControlName="telefone" name="telefone" id="telefone" mask="(00) 0000-0000||(00) 0 0000-0000" class="form-control"
                    placeholder="Insira seu telefone.." required
                    [class.is-invalid]=" contactFormGroup.get('telefone').touched && contactFormGroup.get('telefone').hasError('required')">
                  <div class="invalid-feedback" *ngIf="contactFormGroup.get('telefone').touched && contactFormGroup.get('telefone').hasError('required')">Campo obrigatório</div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group app-label">
                  <label for="genero">Gênero</label>
                  <select formControlName="genero" name="genero" id="genero" class="form-control" required
                    [class.is-invalid]=" contactFormGroup.get('genero').touched && contactFormGroup.get('genero').hasError('required')">
                    <option value="" selected disabled>Selecione</option>
                    <option value="masculino">Masculino</option>
                    <option value="feminino">Feminino</option>
                    <option value="outro">Outro</option>
                  </select>
                  <div class="invalid-feedback" *ngIf="contactFormGroup.get('genero').touched && contactFormGroup.get('genero').hasError('required')">Campo obrigatório</div>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-group app-label">
                  <label for="assunto">Assunto</label>
                  <input type="text" class="form-control" formControlName="assunto"  name="assunto" id="assunto" placeholder="Insira o Assunto.." required maxLength="100"
                    [class.is-invalid]=" contactFormGroup.get('assunto').touched && contactFormGroup.get('assunto').hasError('required')">
                  <div class="invalid-feedback" *ngIf="contactFormGroup.get('assunto').touched && contactFormGroup.get('assunto').hasError('required')">Campo obrigatório</div>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-group app-label">
                  <label for="mensagem">Mensagem</label>
                  <textarea formControlName="mensagem" name="mensagem" id="mensagem" rows="3" class="form-control"
                    placeholder="Insira sua menssagem.." required maxlength="500"
                    [class.is-invalid]=" contactFormGroup.get('mensagem').touched && contactFormGroup.get('mensagem').hasError('required')"></textarea>
                  <div class="invalid-feedback" *ngIf="contactFormGroup.get('mensagem').touched && contactFormGroup.get('mensagem').hasError('required')">Campo obrigatório</div>
                </div>
              </div>
              <div class="col-lg-12 mt-3">
                <div class="form-group app-label">
                  <re-captcha
                    formControlName="recaptchaReactive"
                    (resolved)="addTokenLog('Reactive form mode resolved', $event)"
                    [class.is-invalid]="contactFormGroup.get('recaptchaReactive').touched && contactFormGroup.get('recaptchaReactive').hasError('required')"
                  ></re-captcha>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-sm-12">
                <button type="button" [disabled]="contactFormGroup.invalid" (click)="contact()" id="submit" name="send" class="btn btn-warning">Enviar Mensagem <i
                    class="mdi mdi-telegram ml-2"></i></button>
                <div id="simple-msg"></div>
              </div>
            </div>
            <div class="w-100 text-center mt-3" *ngIf="messageContact">
              <ngb-alert [dismissible]="true" [type]="type">{{ messageContact }}</ngb-alert>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Contact Us End -->

<!-- Subscribe Start -->
<section class="section bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-7">
        <div class="row">
          <div class="col-4">
            <div class="subscribe-icon">
              <img src="assets/images/icon/1.png" alt="" class="img-fluid mx-auto d-block">
            </div>
          </div>
          <div class="col-4">
            <div class="subscribe-icon">
              <img src="assets/images/icon/2.png" alt="" class="img-fluid mx-auto d-block">
            </div>
          </div>
          <div class="col-4">
            <div class="">
              <img src="assets/images/icon/3.png" alt="" class="img-fluid mx-auto d-block">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="text-center mt-5 mb-4">
          <h6 class="text-muted font-weight-normal">Assine nossa newsletter para novos conteúdos, <span
              class="d-block mt-2">Atualização e Demonstrações!</span></h6>
        </div>
        <div class="text-center subscribe-form mt-4">
          <form [formGroup]="newsletterFormGroup">
            <input type="email" formControlName="email" placeholder="Seu melhor Endereço de E-mail ..." maxlength="50"  >
            <button type="submit" [disabled]="newsletterFormGroup.invalid" class="btn btn-warning" (click)="subscribeTo()">Inscrever-se</button>
            <div class="invalid-feedback" style="text-align: left;margin-left: 30px;" *ngIf="newsletterFormGroup.get('email').touched && newsletterFormGroup.get('email').hasError('required')">Campo obrigatório</div>
          </form>
        </div>
        <div class="w-100 text-center mt-3" *ngIf="message">
          <ngb-alert [dismissible]="true" [type]="type">{{ message }}</ngb-alert>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Subscribe End -->