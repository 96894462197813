import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CountToModule } from 'angular-count-to';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ScrollspyDirective } from './scrollspy.directive';

import { AboutComponent } from './about/about.component';
import { ClientsComponent } from './clients/clients.component';
import { ContactComponent } from './contact/contact.component';
import { FeaturesComponent } from './features/features.component';
import { FooterComponent } from './footer/footer.component';
import { ServicesComponent } from './services/services.component';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbAlertConfig, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from "ng-recaptcha";
import { CountUpModule } from 'ngx-countup';
import { NgxMaskModule } from 'ngx-mask';
import { CounterComponent } from './counter/counter.component';
import { environment } from 'src/environments/environment';


@NgModule({
  declarations: [
    AboutComponent, 
    ClientsComponent, 
    ContactComponent, 
    FeaturesComponent, 
    FooterComponent, 
    ServicesComponent, 
    ScrollspyDirective, 
    CounterComponent
  ],
  imports: [
    CommonModule, 
    CarouselModule, 
    CountToModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbAlertModule,
    CountUpModule,
    NgxMaskModule.forRoot(),
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  exports: [
    AboutComponent, 
    ClientsComponent, 
    ContactComponent, 
    FeaturesComponent, 
    FooterComponent, 
    ServicesComponent, 
    ScrollspyDirective,
    CounterComponent,
  ],
  providers: [
    NgbAlertConfig,,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.RECAPTCHA_KEY } as RecaptchaSettings,
    },
  ],
})
export class SharedModule { }
