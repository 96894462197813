<!-- About us Start -->
<section class="section bg-light" id="about">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8">
                <div class="title text-center mb-5">
                    <h3 class="font-weight-normal text-dark">Quem <span class="text-warning">Somos</span></h3>
                    <p class="text-muted">Nascemos da necessidade de simplificaçāo dos processos de compras, buscando a melhoria contínua de nossos softwares e dos processos de nossos clientes.</p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4">
                <h2 class="font-weight-light line-height-1_6 text-dark mb-4">"O simples é sempre melhor que o complicado.     Jorge Paulo Lemann"</h2>
            </div>
            <div class="col-md-7 offset-md-1">
                <div class="row">
                    <div class="col-md-6">
                        <h6 class="text-dark font-weight-light f-20 mb-3">Nossa Missāo</h6>
                        <p class="text-muted font-weight-light">Transformar a forma como empresas realizam cotações, oferecendo inovação tecnológica que agiliza processos, otimiza recursos e coloca o cliente no centro de nossas ações.</p>
                    </div>
                    <div class="col-md-6">
                        <h6 class="text-dark font-weight-light f-20 mb-3">Nossa Visāo</h6>
                        <p class="text-muted font-weight-light">Ser a principal referência em soluções de cotações no mercado, oferecendo tecnologia de ponta que capacita nossos clientes a expandir seus negócios de forma eficiente e sustentável, sem comprometer a qualidade do serviço prestado.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- About us End -->