import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InitialDataResolver } from './initialData.resolver';

const routes: Routes = [
  { 
    path: '', 
    loadChildren: () => import('./layouts/layouts.module').then(m => m.LayoutsModule),
    resolve: { initialData: InitialDataResolver }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
