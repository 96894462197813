
<!-- Counter Start -->
<section class="section counter-bg" style="background: url(assets/images/counter-bg-1.jpg) center center">
    <div class="bg-overlay op-75"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6">
                <div class="text-center mb-5">
                    <h2 class="font-weight-normal text-white text-opacity mb-2">Nossos Números</h2>
                    <!-- <h5 class="font-weight-normal text-white text-opacity-70">More Than 1000+ Work Successfully</h5> -->
                </div>
            </div>
        </div>
        <div class="row" id="counter">
            <div class="col-lg-4 col-md-6">
                <div class="counter-box text-center text-white text-opacity mb-4 mb-lg-0">
                    <i class="pe-7s-users counter-icon mb-4"></i>
                    <h2 class="mb-2 font-weight-normal">+<span class="counter-value mt-4" [countUp]="dados.consolidado.total_vendedores" [options]="{ enableScrollSpy: true,separator: '.',duration: '5' }" ></span></h2>
                    <p class="mb-0 text-white text-opacity-70 text-uppercase f-14">Vendedores</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="counter-box text-center text-white text-opacity mb-4 mb-lg-0">
                    <i class="pe-7s-portfolio counter-icon mb-4"></i>
                    <h2 class="mb-2 font-weight-normal">+<span class="counter-value mt-4"  [countUp]="dados.consolidado.total_fornecedores"  [options]="{ enableScrollSpy: true,separator: '.',duration: '5' }" ></span></h2>
                    <p class="mb-0 text-white text-opacity-70 text-uppercase f-14">Fornecedores</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="counter-box text-center text-white text-opacity mb-4 mb-lg-0">
                    <i class="pe-7s-file counter-icon mb-4"></i>
                    <h2 class="mb-2 font-weight-normal">+<span class="counter-value mt-4"  [countUp]="dados.consolidado.total_cotacoes"  [options]="{ enableScrollSpy: true,separator: '.',duration: '5' }" ></span></h2>
                    <p class="mb-0 text-white text-opacity-70 text-uppercase f-14">Cotações Enviadas</p>
                </div>
            </div>
            <!-- <div class="col-lg-3 col-md-6">
                <div class="counter-box text-center text-white text-opacity mb-4 mb-lg-0">
                    <i class="pe-7s-diamond counter-icon mb-4"></i>
                    <h2 class="mb-2 font-weight-normal">+<span class="counter-value mt-4"  [countUp]="dados.consolidado.total_clientes"  [options]="{ enableScrollSpy: true,separator: '.',duration: '5' }" ></span></h2>
                    <p class="mb-0 text-white text-opacity-70 text-uppercase f-14">Clientes Satisfeitos</p>
                </div>
            </div> -->
        </div>
    </div>
</section>
<!-- Counter End -->
