import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import InitialDataInterface from '../interfaces/initalData.interface';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CounterComponent implements OnInit {

  dados: InitialDataInterface;
  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.dados = this.route.snapshot.data.initialData as InitialDataInterface;
  }
}
